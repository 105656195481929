import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import {
  CenteredSizedContainer,
  RowCentredContent,
} from '../common/ui/container';
import { LightPurpleTitle } from '../common/ui/typo';
import { Logo } from '../common/ui/image';
import { TitleWrapper, ButtonWrapper } from '../resetPassword/styles';

import logoImage from '../../assets/img/ui/logo.svg';
import { SET_FROM_WEBSITE } from '../../actions/actionTypes';
import { isOnMobile } from '../../utils/detectDevice';
import classNames from 'classnames';

class ResetPasswordSuccess extends Component {
  redirectToHomepage = () => {
    const { resetFromWebsite, fromWebsite } = this.props;

    resetFromWebsite();
    if (fromWebsite) {
      navigate('/');
    } else {
      window.location.replace('https://www.monimalz.com/app');
    }
  };

  buttonWording() {
    const { fromWebsite, wordings } = this.props;

    if (fromWebsite) {
      return wordings.button;
    } else if (!fromWebsite && !this.isOnMobile) {
      return wordings.app_indication;
    } else {
      return wordings.button_app;
    }
  }

  isOnMobile = isOnMobile();

  render() {
    const { wordings, fromWebsite } = this.props;
    const buttonClass = classNames({
      'yellow-button': true,
      disabled: !fromWebsite && !this.isOnMobile,
    });

    return (
      <CenteredSizedContainer>
        <RowCentredContent>
          <Logo src={logoImage} onClick={this.redirectToHomepage} />
        </RowCentredContent>

        <TitleWrapper>
          <LightPurpleTitle>{wordings.title}</LightPurpleTitle>
        </TitleWrapper>
        <ButtonWrapper>
          <button
            className={buttonClass}
            onClick={this.redirectToHomepage}
            disabled={!fromWebsite && !this.isOnMobile}
          >
            {this.buttonWording()}
          </button>
        </ButtonWrapper>
      </CenteredSizedContainer>
    );
  }
}

const mapStateToProps = ({ app: { fromWebsite } }) => {
  return {
    fromWebsite,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetFromWebsite: () => {
      dispatch({ type: SET_FROM_WEBSITE, payload: { fromWebsite: false } });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordSuccess);
