import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import ResetPasswordSuccessComponent from '../components/resetPasswordSuccess/';

const ResetPasswordPage = ({ data }) => (
  <Layout
    title={data.wordings.fr.reset_password_success.page_title}
    description={data.wordings.fr.reset_password_success.description}
  >
    <ResetPasswordSuccessComponent
      wordings={data.wordings.fr.reset_password_success}
    />
  </Layout>
);

ResetPasswordPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ResetPasswordPage;

export const query = graphql`
  query ResetPasswordSuccessPageQuery {
    wordings {
      fr {
        reset_password_success {
          page_title
          description
          title
          button
          button_app
          app_indication
        }
      }
    }
  }
`;
