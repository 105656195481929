import styled from 'styled-components';

import { LIGHT_PURPLE } from '../../../constants/colors';

const Title = styled.h1`
  font-size: 2em;
  font-family: 'DrukWide';
  letter-spacing: 0.05em;
  text-align: center;
  margin: 0;
  text-transform: none;
`;

export const LightPurpleTitle = styled(Title)`
  color: ${LIGHT_PURPLE};
`;
