import styled from 'styled-components';

import {
  ColumnCenteredContent,
  RowCentredContent,
} from '../common/ui/container';

export const InputWrapper = styled(ColumnCenteredContent)`
  margin: 4em 0;
`;

export const TitleWrapper = styled(RowCentredContent)`
  margin: 10em 0 0 0;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
`;
