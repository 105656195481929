import styled from 'styled-components';

export const CenteredSizedContainer = styled.div`
  max-width: 700px;
  margin: 0 auto 50px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowCentredContent = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnCenteredContent = styled(Column)`
  align-items: center;
  justify-content: center;
`;

export const ColumnSpaceBetween = styled(Column)`
  align-items: center;
  justify-content: space-between;
`;
