// Main Colors
export const DARK_PURPLE = '#3C1A68';
export const LIGHT_PURPLE = '#8E00C0';
export const BLUE = '#65ACFF';
export const PINK = '#FF5A77';
export const YELLOW = '#FFFE82';

// UTILS
export const INPUT_BORDER_COLOR = '#8D8991';

// STATUS COLOR
export const STATUS_COLORS = {
  PENDING: '#EABC83',
  PROCESSING: '#EABC83',
  PAYMENT_PENDING: '#EABC83',
  PAYMENT_DUE: '#FF3232',
  PAID: '#EABC83',
  PREPPING: '#EABC83',
  SHIPPED: '#1CC268',
  COMPLETED: '#1CC268',
};
